
html, body{
  margin:0;
  padding:0;
  width:100vw;
  overflow-x: hidden;
}

*{
    box-sizing: border-box;
}

.app {
  position: relative;
  display:block;
  margin:0;
  padding:0;
  min-height: 100vh;
  position:relative;
  background-color: white;
}

.container{
  position: relative;
  display:block;
  margin:0;
  padding:0;
  min-height: calc( 100vh - 100px);
}

.content{
  position: relative;
  display:block;
  width: 80vw;
  margin:0 auto;
  padding:0;
}

.body-copy{
  margin-top:100px;
  padding:0 25px 100px 25px;
  color:$dark-blue;

  h1,h2,h3,h4{
      text-transform: uppercase;
      font-family: 'Gotham Medium';
  }

  p{
      font-family: 'Gotham Book';
      font-size: 16px;
      line-height: 24px;
  }

  ul.no-bullet{
    list-style: none;
  }

  ol,
  ul{
      font-family: 'Gotham Book';
      font-size: 16px;
      line-height: 24px;

      li{
          margin:0 0 15px 0;
      }
  }
}
 
  