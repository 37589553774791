.hero {
    position:relative;
    margin:0;
    padding:0;
    top:0;
    width:100vw;
    background: black;
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-bottom: 75px;
    //animation: zoom 2.5s;
 
    .hero-container{
      position: relative;
      padding:115px 0 0 0;
      margin:0 auto;
      max-width:760px;
      width:80vw;
      color: white;
      text-align:center;

      .hero-title{
          margin-top:-24px;
          img{
            max-width:100%;
            height:auto;
          }
      }

      h3{
        margin:5px auto 35px auto;
        letter-spacing: 2px;
        max-width: 420px;
        width:75%;
        font-family: 'Gotham Light';
        font-size: 17px;
      }

      button.hero-cta{
        text-transform:uppercase;
        font-size:18px;
        padding:13px 40px 13px 40px;
        border-top-right-radius:10px;
        border-top-left-radius: 0;
        border-bottom-right-radius:0;
        border-bottom-left-radius: 10px;
        border:1px solid transparent;
        color:white;
        background-color: rgba(206, 26, 230, 0.80);
        font-family: 'Gotham Bold';
        cursor: pointer;
        outline:none;
        transition: $rollover;

        &:hover{
          border:1px solid $pink;
          background-color:white;
          color:$pink;
        }

      }

      .video-container{
          margin:75px auto;
          max-width: 690px;
      }

      .slide-container{
        margin:75px auto;
        max-width: 600px;
      }
   
    }
}


@keyframes zoom {
  from {
    background-position: center center;
    background-size: 125%;
  }
  to {
    background-position: top center;
    background-size: 100%;
  }
}