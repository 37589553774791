
  .register {
    position:relative;
    margin:0 auto;
    width:100vw;
    height:auto;
    background-color: white;
    font-family: 'Gotham Medium';
  
    .registerContain{
        margin:0 auto;
        max-width:1100px;
        width:75vw;
        height:auto;
        clear:both;
        overflow: visible;

    }

    h4{
        margin:10px 0 10px 0;
        padding:0;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 2px;
        color: $dark-blue;  
    }

  }

  .device{
    display:inline-block;
    vertical-align:top;
    width:60%;
    margin-top:-65px;
    margin-left:-35px;

    .deviceImg{
      width:100%;
      height:auto;
    }
  }
  
  .form{
    display:inline-block;
    vertical-align:top;
    margin:0 0 0 25px;
    padding:20px;
    width:40%;
  

    .input{
      position:relative;
      margin:10px 0;
      
      .chevron{
          position:absolute;
          top:8px;
          left:10px;
          width:10px;
          height:10px;
          color:$medium-blue;

          svg{
            width:100%;
            height:auto;
          }
      }
      
      input{
          padding:8px 10px 8px 30px;
          width:100%;
          border:0;
          font-size: 17px;
          font-family: 'Gotham Book';
          color:#FFF;
          background-color: $light-blue;
      
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: white;
            }
      
            &::-moz-placeholder { /* Firefox 19+ */
              color: white;
            }
      
            &:-ms-input-placeholder { /* IE 10+ */
              color: white;
            }
      
            &:-moz-placeholder { /* Firefox 18- */
              color: white;
            }
        }
    }


    .warning{
      display:none;
      color:$dark-blue;
      padding:5px;
      font-size: 12px;
      font-family: 'Gotham Book';
    }

    .warning.on{
      display:block;
    }
  
    button{
      margin-top:15px;
      padding:10px 30px;
      border:1px solid transparent;  
      text-transform: uppercase;
      color:white;
      font-size:20px;
      background-color: $pink;
      border-top-right-radius:10px;
      border-top-left-radius: 0;
      border-bottom-right-radius:0;
      border-bottom-left-radius: 10px;
      font-family: 'Gotham Bold';
      cursor:pointer;
      transition: $rollover;
      &:hover{
        border:1px solid $pink;
        background-color:white;
        color:$pink;
      }
      
    }

    .response{
      display:none;
      padding-top:0;
      text-align: center;

      .success,
      .error,
      .userExists{
        display:none;
      }

      h4{
        margin:40px 0;
        padding:0;
        text-transform: capitalize;
        font-size: 22px;
      }
    }

  }

  .form.sent{

    .submit{
      display:none;
    }
    .response{
      display:block;
    }
  }

  .form.sent.sucess{

    .success{
      display:block;
    }

    .trying,
    .error,
    .userExists{
      display:none;
    }

  }
  
  .form.sent.error{

    .error{
      display:block;
    }

    .trying,
    .success,
    .userExists{
      display:none;
    }

  }

  .form.sent.isUser{

    .userExists{
      display:block;
    }

    .trying,
    .success,
    .error{
      display:none;
    }
   
  }
  
  