
@media only screen and (max-width: 1024px) {

	.app.show-breakpoints{
		border:1px solid green;
    }

    .register {
        margin:100px auto; 
    }
    
    .device{
        padding-top:25px;
        padding-left:15px;
        margin-top:0;
    }

    .form{
        h4{
            font-size: 20px;
        }
        
    }
}

@media only screen and (max-width: 768px) {
	
	.app.show-breakpoints{
		border:1px solid orange;
    }


  .menu{
    .menu-container{
      right:-100vw;
      width:100vw;
    }
  }

  .container{
    min-height: calc( 100vh - 165px);
  }


    .count-down{
      .counter-title{
        font-size:14px;
      }

      ul.counter{
          li.number{
            font-size:28px;
            width:28px;
          }
      }

    }

    .hero {
        height:auto;
        .hero-container{
            margin:0 auto;
            h3{
              margin:10px auto;
              width:100%;
            }
        }
    }

    .swiper{
      width:80%;
    }

    .register {
        margin:50px auto; 
    }

    .registerContain{
        display: flex;
        flex-direction: column;
    }
    
    .device{
       width:100%;
       margin-top:25px;
       margin-left:0;
       order: 2;
    }

    .form{
        float:none;
        text-align: center;
        margin:0 0 0 0;
        padding:10px;
        width:100%;
        order: 1;
    }

    .footer{
        padding:20px 0;
        height:165px;
        text-align: center;
        background-position-x: center;
        background-position-y: bottom;
        background-size:100%;
    }

    .footerinfo{
      min-height:125px;
    }

    .logo{
      margin:0 auto;
    }

    .lense-flare-footer{
        position:absolute;
        bottom:0;
        left:0;
        right:0;
      }

    .col-left{
        float:none;
      }

      .col-right{
        float:none;
      }

    .social{
        margin:10px 0;
    }

    .copyright{
        text-align: center;
    }

} 

@media only screen and (max-width: 568px) {

  .hero {
    height:auto;
    .hero-container{
        margin:0 auto;

        .hero-title{
          margin-top:-15px;
        }

        h3{
          margin:10px auto 25px auto;
          width:100%;
          font-size: 16px;  
        }
    }
}

.register {
  margin:20px auto; 
}

  .social{
    li{
      margin:5px;
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 320px) {
  

  .count-down{
    .counter-title{
      font-size:12px;
    }

    ul.counter{
        li.number{
          padding:0 0 0 0;
          font-size:26px;
          width:20px;
        }
    }

  }
  
  .hero {
      .hero-container{
          margin:0 auto;
          h3{
            margin:10px auto 25px auto;
              width:100%;
              font-size: 14px;  
          }
      }
  }
 

   
  .register {
    margin:0 auto; 
      h4{
        font-size: 18px;
    }
  }
 
  

}
