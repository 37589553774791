

/* Gotham ------------------------------------------------------------------- */
@font-face {
    font-family: 'Gotham Bold';
    src: url('Gotham/Gotham-Bold.eot');
    src: url('Gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('Gotham/Gotham-Bold.woff2') format('woff2'),
    url('Gotham/Gotham-Bold.woff') format('woff'),
    url('Gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: normal;
    font-style: normal; 
}

@font-face {
    font-family: 'Gotham Medium';
    src: url('Gotham/Gotham-Medium.eot');
    src: url('Gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('Gotham/Gotham-Medium.woff2') format('woff2'),
    url('Gotham/Gotham-Medium.woff') format('woff'),
    url('Gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: normal;
    font-style: normal; 
}


@font-face {
    font-family: 'Gotham Book';
    src: url('Gotham/Gotham-Book.eot');
    src: url('Gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('Gotham/Gotham-Book.woff2') format('woff2'),
    url('Gotham/Gotham-Book.woff') format('woff'),
    url('Gotham/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Light';
    src: url('Gotham/Gotham-Light.eot');
    src: url('Gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('Gotham/Gotham-Light.woff2') format('woff2'),
    url('Gotham/Gotham-Light.woff') format('woff'),
    url('Gotham/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: normal;
    font-style: normal; 
}

/* HelveticaNeue ------------------------------------------------------------------- */

@font-face {
    font-family: 'HelveticaNeue Medium Condensed';
    src: url('HelveticaNeue/HelveNeuMedCon.eot');
    src: url('HelveticaNeue/HelveNeuMedCon.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeue/HelveNeuMedCon.woff2') format('woff2'),
    url('HelveticaNeue/HelveNeuMedCon.woff') format('woff'),
    url('HelveticaNeue/HelveNeuMedCon.svg#HelveticaNeue-MediumCond') format('svg');
    font-weight: normal;
    font-style: normal; 
}