
/* colors */
$background-color:white;
$dark-blue: #1e4061;
$medium-blue: #144162;
$light-blue: #98abbc;
$highlight: #49b7e6;
$pink:#cf1ae5;

/* transitions */
$rollover: all .25s ease;