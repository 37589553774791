.count-down{
    margin-top:85px auto 0 auto;
    max-width:400px;
    width:100%;
    text-align: center;
    margin:0 auto;
    z-index:50;
    
    .counter-title{
        padding:0;
        margin:0 0 5px 0;
        color:white;
        font-size:16px;
        text-transform: uppercase;
        font-family: 'Gotham Medium';
    }

    ul.counter{
       margin:0 auto;
       padding:0;
       list-style: none;

       li{
        display:inline-block;
        margin:2px;
        vertical-align: top;
       }

        li.number{
            background: linear-gradient(0deg, rgba(247,247,247,0.35) 0%, rgba(118,143,162,0.35) 50%, rgba(175,183,189,0.45) 51%, rgba(118,143,162,0.45) 100%);
            font-size:34px;
            letter-spacing: 0;
            padding:4px 0 4px 0;
            color:white;
            border-radius: 5px;
            font-family: 'HelveticaNeue Medium Condensed';
            text-align:center;
            width:32px;
        }

        li.spacer{
            padding:5px 1px 5px 1px;
        }
    }
}