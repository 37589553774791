
.footer {
    background-color: $medium-blue;
    color:#FFF;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width:1200px;
    width:90vw;
    height:100px;
    left:0;
    right:0;
    margin:0 auto;
    bottom:0;
    clear:both;
    overflow:hidden;
}


.footerinfo{
  position:absolute;
  padding:0px 40px 0 40px;
  bottom:0;
  min-height:65px;
  width:100%;
  z-index:10;
}

.logo{
  width:145px;
  img{
    width:100%;
    height:auto;
  }
}

.lense-flare-footer{
  position:absolute;
  opacity:.7;
  bottom:-5px;
  left:-200px;
  z-index:0;
  img{
    width:100%;
    height:auto;
  }

}

.col-left{
  position:relative;
  float:left;
}

.col-right{
  float:right;
}

.social{
  margin:0px;
  padding:0;
  list-style: none;

  li{
    margin:0 8px 0 8px;
    width: 24px;
    display: inline-block;
    vertical-align: top;
  

    a{
        color: white;
        text-decoration: none;
        transition:$rollover;
        svg{
          transition:$rollover;
          color: white;
        }
        
        &:hover{
          color: $pink;
          svg{
            color: $pink;
          }
        }
    }

    &:nth-child(4){
      margin-left:30px;
    }

  }
  
  .textLink{
    font-size:12px;
    width:auto;
  }

}

.copyright{
  width:100%;
  padding:0 8px 5px 0;
  text-align: right;
  font-size: 11px;
  line-height: 0;
  clear:both;
}


  