.video{
    margin:0 auto;
    position: relative;
    padding-bottom: 51%; /* NOT 16:9  */
    padding-top: 25px;
    height: 0;
    border:10px solid white;
    border-radius:10px;
    background-color:black;
    
    iframe{
        position: absolute;
        background-color:black;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}