
.menu{
    position:fixed;
    background-color: transparent;
    top:0;
    right:0;
    height:100vh;
    z-index:100;

    .menu-toggle{
        position:absolute;
        right:10vw;
        top:0;
        padding:20px 25px;
        .hamburger,
        .close-menu{
            width:25px;
            cursor:pointer;
            svg{
                transition:$rollover;
                color: $dark-blue;
            }
            &:hover{
                svg{
                    color: $pink;
                }
            }
        }
        .close-menu{
            display:none;
        }
        z-index:100;
    }

    .menu-container{
        position:absolute;
        visibility: hidden;
        right:-40vw;
        top:0;
        width:40vw;
        background-color: $medium-blue;
        padding:75px 25px;
        height:100vh;
        transition: all .25s ease-in;

        .menu-links{
            text-align: right;
            margin:0 10vw 0 0;
            padding:0 0 0 0;
            list-style: none;

            li{
                padding:10px 0 10px 0;
                font-family: 'Gotham Medium';
                font-size: 30px;

                a{
                    color:white;
                    text-decoration: none;
                    text-transform: uppercase;
                    transition: $rollover;
                    &:hover{
                        color:$pink;
                    }
                }

            }
        }

        ul.social{
            text-align: right;
            margin:25px 10vw 0 0;
            padding:0;
            list-style: none;

            li{

                margin:0 0 5px 10px;

                a{
                    color:white;
                    transition: $rollover;
                    &:hover{
                        color:$pink;
                    }
                }

                &:nth-child(4){
                    margin-top:45px;
                    margin-left:0;
                  }
            }

            li.textLink{
                display:block;
            }
          
        }

    }
}

.menu.on{
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5); 
    visibility: visible;
    .menu-container{
        right:0;
        visibility: visible;
    }
    .menu-toggle{
        .hamburger{
            display:none;
        }
        .close-menu{
            display:block;
            cursor:pointer;
            transition:$rollover;
            svg{
                color:white;
            }
            &:hover{
                svg{
                    color:$pink;
                }
            }
        }
    }

}