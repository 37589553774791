
.header {
  display:block;
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  z-index:15;

 .header-bg{
   position:absolute;
   width:100vw;
   height:65px;
   padding:25px 35px 20px 10vw;
   background-color: #FFFFFF;
   border-bottom:1px solid $light-blue;
   z-index:20;

   .header-logo{
     width:145px;
     img{
       width:100%;
       height:auto;
     }

   }

 }

 .lense-flare-top{
   display:none;
   position:absolute;
   top:10px;
   left:0;
   right:0;
   margin: 0 auto;
   z-index:10;
   img{
     width:100%;
     height:auto;
   }
 }

}
