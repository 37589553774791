
.swiper{
    position: relative;
    width:100%;
    margin: 0 auto;

  
    .swiper-container{
        position: relative;
        margin: 0 auto;

        .swiper-wrapper{
            .swiper-slide{
                width:100%;

                img{
                    border:10px solid white;
                    border-radius: 10px;
                    width:100%;
                    height: auto;
                }

                h3{
                    margin:30px auto;
                    color: white;
                    text-transform: uppercase;
                    font-family: 'Gotham Bold';
                }
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev{
        background-image:none;
        color:$pink;
        outline:none;
        font-size: 52px;
        transition:$rollover;
        &:hover{
            color:white;
        }
    }

    .swiper-button-next{
        right:-55px;
    }
    .swiper-button-prev{
        left:-55px;
    }

    .swiper-pagination{
        bottom:-45px;
        width:100%;
        margin:0 auto;

        .swiper-pagination-bullet.swiper-pagination-bullet-active{
            opacity:1;

        }
        .swiper-pagination-bullet{
            background: $pink;
            width: 15px;
            height: 15px;
            opacity:.6;
            margin:5px;
        }
    }

       
}